const SizeSelector = ({ sizeKey, setSizeKey, sku }) => {

  const SKU_PREFIX = sku.value.slice(0, 3);

  const UNKNOWN_OPTION = { name: "Select a size", sizeKey: "" }

  const CANVAS_OPTIONS = [
    { name: "8x10 Canvas - Portrait", sizeKey: "CANVAS8x10P" },
    { name: "8x10 Canvas - Landscape", sizeKey: "CANVAS8x10L" },
    { name: "16x20 Canvas - Portrait", sizeKey: "CANVAS16x20P" },
    { name: "16x20 Canvas - Landscape", sizeKey: "CANVAS16x20L" },
    { name: "16x24 Canvas - Portrait", sizeKey: "CANVAS16x24P" },
    { name: "16x24 Canvas - Landscape", sizeKey: "CANVAS16x24L" },
    { name: "18x24 Canvas - Portrait", sizeKey: "CANVAS20x24P" },
    { name: "18x24 Canvas - Landscape", sizeKey: "CANVAS20x24L" },
    { name: "20x30 Canvas - Portrait", sizeKey: "CANVAS20x30P" },
    { name: "20x30 Canvas - Landscape", sizeKey: "CANVAS20x30L" },
    { name: "24x36 Canvas - Portrait", sizeKey: "CANVAS24x36P" },
    { name: "24x36 Canvas - Landscape", sizeKey: "CANVAS24x36L" },
  ]
  const MATTE_OPTIONS = [
    { name: "8x10 Matte - Portrait", sizeKey: "MATTE8x10P" },
    { name: "8x10 Matte - Landscape", sizeKey: "MATTE8x10L" },
    { name: "16x20 Matte - Portrait", sizeKey: "MATTE16x20P" },
    { name: "16x20 Matte - Landscape", sizeKey: "MATTE16x20L" },
    { name: "16x24 Matte - Portrait", sizeKey: "MATTE16x24P" },
    { name: "16x24 Matte - Landscape", sizeKey: "MATTE16x24L" },
    { name: "18x24 Matte - Portrait", sizeKey: "MATTE20x24P" },
    { name: "18x24 Matte - Landscape", sizeKey: "MATTE20x24L" },
    { name: "20x30 Matte - Portrait", sizeKey: "MATTE20x30P" },
    { name: "20x30 Matte - Landscape", sizeKey: "MATTE20x30L" },
    { name: "24x36 Matte - Portrait", sizeKey: "MATTE24x36P" },
    { name: "24x36 Matte - Landscape", sizeKey: "MATTE24x36L" },
  ]

  const ORNAMENT_OPTIONS = [
    { name: "3x3 Ornament", sizeKey: "ORNAMENT3x3" },
    { name: "Porcelein Heart Ornament", sizeKey: "HEARTORNAMENT" }
  ]

  const FRAME_OPTIONS = [
    { name: "8x10 Frame - Landscape", sizeKey: "FRAME8x10L" },
    { name: "10x12.5 Frame - Landscape", sizeKey: "FRAME10x12.5L" },
  ]

  const options = [
      UNKNOWN_OPTION,
    ...CANVAS_OPTIONS,
    ...MATTE_OPTIONS,
    ...ORNAMENT_OPTIONS,
    ...FRAME_OPTIONS
  ]

  const handleOnChange = (e) => {
    const key = options.find(option => option.name === e.target.value).sizeKey;
    setSizeKey(key);
  }

  return (
    <div className="tw-p-4">
      <select name="print_dimensions"
        className="tw-w-full tw-p-4"
        onChange={handleOnChange}
        value={options.find(option => option.sizeKey === sizeKey)?.name || "Select a size"}
      >
        {options.map(option => (
          <option key={option.sizeKey} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SizeSelector;

import { Toolbar } from '@mui/material';
import { useRecoilState } from 'recoil';
import HorizontalScroll from "@/components/HorizontalScroll";
import { Button } from "@/components/Button";
import ButtonGroup from "@/components/ButtonGroup";
import { AllCapsIcon, CenterAlignDocumentIcon, CenterAlignIcon, LeftAlignIcon, LowerCaseIcon, RightAlignIcon, TitleCaseIcon } from '@/components/Icons';
// signals
import { canvas } from "@/signals/canvas";

const FormatToolbar = () => {

  // write a function that takes the active object and checks if it is a group of objects
  // if it is a group of objects return the group, if its not then return the object inside an array
  // so that we can map over it and apply the function to it the same way

  const getActiveOrGroupedObject = (canvas) => {
    const activeObject = canvas.value.getActiveObject();
    return activeObject.get('text') ? activeObject : activeObject.getObjects()[0];
  }


  const handleBold = () => {
    canvas.value.getActiveObject().get("fontWeight") === "bold" ? canvas.value.getActiveObject().set("fontWeight", "normal") : canvas.value.getActiveObject().set("fontWeight", "bold")
    canvas.value.renderAll()
  }
  const handleItalic = () => {
    canvas.value.getActiveObject().get("fontStyle") === "italic" ? canvas.value.getActiveObject().set("fontStyle", "normal") : canvas.value.getActiveObject().set("fontStyle", "italic")
    canvas.value.renderAll()
  }
  const upperCase = () => {
    const obj = getActiveOrGroupedObject(canvas);
    obj.set("text", obj.get("text").toUpperCase());
    canvas.value.renderAll();
  }
  const pascalCase = () => {
    const obj = getActiveOrGroupedObject(canvas);
    obj.set("text", obj.get("text").toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase()));
    canvas.value.renderAll();
  }
  const lowerCase = () => {
    const obj = getActiveOrGroupedObject(canvas);
    obj.set("text", obj.get("text").toLowerCase());
    canvas.value.renderAll();
  }

  const setTextAlignment = (canvas, alignment) => {
    const obj = getActiveOrGroupedObject(canvas);
    obj.set("textAlign", alignment);
    canvas.value.renderAll();
  }
  const alignLeft = () => {
    setTextAlignment(canvas, "left");
  }

  const alignCenter = () => {
    setTextAlignment(canvas, "center");
  }

  const alignRight = () => {
    setTextAlignment(canvas, "right");
  }

  // Main function to center the active object to the background
  const centerToBackgroundHorizontal = () => {
    const activeObject = getActiveOrGroupedObject(canvas);
    const background = canvas.value.getObjects().find(obj => obj.id === "background"); // assuming the background has a name property set to "backgroundRectangle"

    if (!activeObject || !background) {
      return; // Exit if either active object or background rectangle is not found
    }

    const bgCenterX = background.left + background.width / 2;

    const objWidth = activeObject.width * activeObject.scaleX;
    const objHeight = activeObject.height * activeObject.scaleY;

    activeObject.set({
      left: bgCenterX - objWidth / 2,
    });

    activeObject.setCoords(); // Updates corner coordinates
    canvas.value.renderAll();
  }

  const centerToTextboxHorizontal = () => {
    const activeObject = getActiveOrGroupedObject(canvas);
    const background = canvas.value.getObjects().find(obj => obj.id === "textbox"); // assuming the background has a name property set to "backgroundRectangle"

    if (!activeObject || !background) {
      return; // Exit if either active object or background rectangle is not found
    }

    const bgCenterX = background.left + background.width / 2;

    const objWidth = activeObject.width * activeObject.scaleX;
    const objHeight = activeObject.height * activeObject.scaleY;

    activeObject.set({
      left: bgCenterX - objWidth / 2,
    });

    activeObject.setCoords(); // Updates corner coordinates
    canvas.value.renderAll();
  }


  return <Toolbar>
    <HorizontalScroll>

      <ButtonGroup>

        <Button
          variant="navy icon-stacked"
          onClick={upperCase}
          icon={<AllCapsIcon />}
        >All Caps</Button>

        <Button
          variant="navy icon-stacked"
          onClick={pascalCase}
          icon={<TitleCaseIcon />}
        >Title Case</Button>

        <Button
          variant="navy icon-stacked"
          onClick={lowerCase}
          icon={<LowerCaseIcon />}
        >Lowercase</Button>

        <Button
          variant="navy icon-stacked"
          onClick={alignLeft}
          icon={<LeftAlignIcon />}
        >Left Align</Button>

        <Button
          variant="navy icon-stacked"
          onClick={alignCenter}
          icon={<CenterAlignIcon />}
        >Center Align</Button>

        <Button
          variant="navy icon-stacked"
          onClick={alignRight}
          icon={<RightAlignIcon />}
        >Right Align</Button>

        <Button
          variant="navy icon-stacked"
          onClick={centerToBackgroundHorizontal}
          icon={<CenterAlignDocumentIcon />}
        >CENTER TO DOCUMENT</Button>

{/* TODO - Add a simple way for customers to center text to a bounding box when centering to the artboard doesn't make sense */}
        {/* <Button
          variant="navy icon-stacked"
          onClick={centerToTextboxHorizontal}
          icon={<CenterAlignDocumentIcon />}
        >CENTER TO TEXTBOX</Button> */}

      </ButtonGroup>

    </HorizontalScroll>
  </Toolbar>
}

export default FormatToolbar
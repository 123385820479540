import { useRecoilState, useRecoilValue } from "recoil"
import toolbarAtom from "@/atoms/toolbar-atom"
import { Button, Toolbar } from "@mui/material"
// import NavButton from "../Buttons/NavyButton"
import { NavyButton } from "@/components/Button"
import templatesSelector from "@/atoms/config-atom"
// Experimenting with appMode
import appModeAtom from "@/atoms/app-mode-atom"
// signals
import { selection } from "@/signals/selection"
import { canvas } from "@/signals/canvas"

const TitleBar = () => {
  const [toolbar, setToolbar] = useRecoilState(toolbarAtom)
  const templates = useRecoilValue(templatesSelector)

  // Experimenting with appMode
  const appMode = useRecoilValue(appModeAtom)


  const handleClose = () => {
    console.log('handleClose triggered')
    document.body.style.zoom = "100%";
    // Handles nested toolbars, closes in reverse order
    if (toolbar.includes('>')) {
      setToolbar( toolbar.split(' > ')[0] )
    }
    else {
      setToolbar('main')
      // setSelectionType('')
      selection.value = null
      canvas.value.discardActiveObject()
      canvas.value.renderAll()
    }
  }

  const handleTemplates = () => {
    setToolbar('templates')
  }

  // Button Message is dynamic based on the appMode
  const buttonMessage = appMode === 'SHIPSTATION' 
  ? 'CUSTOMIZE YOUR ARTWORK HERE AND CLICK SAVE WHEN DONE'
  : 'Customize your artwork here and add it to the cart when you are done'
  // return inactive button, if no templates
  if(toolbar === 'main' && !templates.length) {
    return <Toolbar>
       <NavyButton
        variant="fullWidth disabled mobile"
     >{buttonMessage}</NavyButton>
     </Toolbar>
  }
  
  // If there are templates, then show the templates on the default toolbar
  if(toolbar === 'main' && templates) {
    return <Toolbar>
    <NavyButton
    fullWidth
    onClick={handleTemplates}
    >templates</NavyButton>
  </Toolbar>
}

if(toolbar !== '' ) {
  return <Toolbar>
    <NavyButton
    variant="navy fullWidth"
    onClick={handleClose}
  // >{toolbar + " X"}</NavButton>
  >{"❮ back"}</NavyButton>
  </Toolbar>
}

}

export default TitleBar
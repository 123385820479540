// replace filename with uuid to

import { v4 as uuidv4 } from 'uuid';

const uploadImageToS3 = async (file) => {
  // Step 1: Fetch the pre-signed URL from your Netlify Function
  try {
    const fileType = file.type;    
    const response = await fetch(`${import.meta.env.VITE_NETLIFY_BASE_URL}/generate-presigned-url`, {
      method: 'POST',
      // TODO - may need to comment out headers, not sure
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ filename: uuidv4(), filetype: file.type }),
    });

    console.log('presigned reponse', response);
    
    if (!response.ok) {
      throw new Error(`Server error: ${response.status}`);
    }

    const { url } = await response.json();


    const params = {
        method: 'PUT',
        headers: {
          'Content-Type': fileType,
        },
        body: file,
      }
        

      console.log(params);  
    // Step 2: Upload the file to the S3 bucket using the pre-signed URL
    const uploadResponse = await fetch(url, params);

    console.log(uploadResponse);

    if (!uploadResponse.ok) {
      throw new Error(`Upload failed: ${uploadResponse.status}`);
    }

    console.log('Upload successful');
  
    console.log('uploadURL: ', uploadResponse.url.split('?')[0])
    return uploadResponse.url.split('?')[0];

  } catch (error) {
    console.error('Error:', error);
  }
}

export default uploadImageToS3;
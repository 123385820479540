import React, { useEffect, useRef } from 'react';
// Recoil
import { useRecoilState } from 'recoil';
// Atoms
import shipstationProjectAtom from '@/atoms/shipstation-project-data-atom';
// pages
import ConfigMaker from '@/pages/admin/ConfigMaker';
import Maker from '@/pages/Maker';
// Hooks
import { useSkuConfig } from '@/hooks/useSkuConfig'; // Adjust the path accordingly
// Signals
import { sku } from '@/signals/sku';


function AppContent() {
  console.log('render AppContent');

  const [shipstationProjectData, setShipstationProjectData] = useRecoilState(shipstationProjectAtom);

  // Check for SKU from global window object (Shopify)
  if(window.sku) {
    sku.value=window.sku
  }
  const { config, isLoading, isError: configError } = useSkuConfig();
  

  console.info('sku from Shopify: ', window.sku);
  console.info('sku from signal/sku : ', sku.value);


    // Disable scrolling when the app is open and manage SKU state
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    

    // Cleanup function to re-enable scrolling
    return () => {
      document.body.style.overflow = 'unset';
      sku.value=""
    };
  }, []); // Empty dependency array ensures this effect runs only once

  // Effect for handling postMessage events from an iframe
  useEffect(() => {
    console.log('AppContent.jsx', 'Setting up postMessage listener');
    const handleReceiveMessage = (event) => {
      // Validate the origin
      // const validOrigins = [
      //   'http://localhost:3000', // Parent app's local development URL
      //   import.meta.env.VITE_PARENT_IFRAME_APP_URL, // iframe's URL
      //   'http://localhost:8888', // iframe's local development URL
      //   'https://leafy-concha-a261bd.netlify.app', // iframe's production URL
      // ];

      // // Validate the origin
      // if (!validOrigins.includes(event.origin)) {
      //   console.log('APP.jsx', 'invalid origin')
      //   return;
      // }


      // Guard the type of message
      const type = event.data.type;
      if (type !== 'shipstationProjectData' && type !== 'shipstationPreview') return
      // console.log('Received message:', event.data);


      // Parse the message data
      const { payload } = event.data;

      console.log('AppContent.jsx', 'type', type)
      console.log('AppContent.jsx', 'payload', payload)

      // Act based on the message type
      if (type === 'shipstationProjectData') {
        const { sku: projectSku, orderNumber, orderUuid, projectUuid, appMode, order, project, isAdmin } = payload;
        // Do something with the received data
        console.log('Received shipstationProjectData: ', projectSku, orderNumber, orderUuid, order, project, isAdmin);
        sku.value=projectSku
        setShipstationProjectData({ payload, type })
        console.log('Updated shipstationProjectData:', shipstationProjectData);
      }

      if (type === 'shipstationPreview') {
        const { sku: previewSku } = payload;
        sku.value=previewSku
        setShipstationProjectData({ payload, type })
      }
    };

    // Listen for message events
    window.addEventListener('message', handleReceiveMessage);

    // Cleanup
    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, []);


  if(isLoading) return <h1>Loading</h1>;
  if(config?.status ==="NEW") return <ConfigMaker />;
  return <Maker />

}

export default AppContent;

/* OptionsToolbar.tsx
  This is the toolbar that appears when the user has selected an option from the main toolbar.
*/
// MUI
import { Toolbar } from "@mui/material"
// utils
import { makeSelection } from "@/utils/select"
// react
import React from "react"
// fabric
import { IText } from "fabric"
// recoil
import { useRecoilState, useRecoilValue } from "recoil"
import toolbarAtom from "@/atoms/toolbar-atom"
// components
import HorizontalScroll from "@/components/HorizontalScroll/HorizontalScroll"
import {Button} from "@/components/Button"
import ButtonGroup from "@/components/ButtonGroup";
import { ColorIcon, FontIcon, PhotoIcon } from "@/components/Icons"
import { Confirm } from "@/components/Dialog";
// hooks
import { useSkuConfig } from "@/hooks"
// signals
import { canvas } from "@/signals/canvas"

const OptionsToolbar = ({ visible }) => {
  console.log('OptionsToolbar', 'useSkuConfig')
  const { config } = useSkuConfig();
  const color_options = config?.color_options ?? [];
  const text_options = config?.text_options ?? [];
  const photo_options = config?.photo_options ?? [];
  const [toolbar, setToolbar] = useRecoilState(toolbarAtom)

  const [showConfirm, setShowConfirm] = React.useState(false);

  const handleSelection = (ids: Array<string>) => {
    console.log('handleSelection')
    makeSelection(canvas, ids)
  }

  const handlePhotoSelection = (ids: Array<string>) => {
    console.log('handlePhotoSelection', ids)
    setToolbar('photo')
    makeSelection(canvas, ids)
  }

  return (<>
    <Toolbar>

      {showConfirm ? <Confirm
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          addTextToCanvas(canvas);
          setShowConfirm(false);
        }}
        title="Add Text To Project"
        message="Add a new text object to the 
        artboard. You will be able to 
        customize the text by clicking 
        on it." />
        : null}

      <HorizontalScroll>

        <ButtonGroup>

          {color_options?.map((option, index) => (
            <Button
              key={`${option.name}-${index}`}
              variant="navy icon-stacked"
              onClick={() => handleSelection(option.members)}
              icon={<ColorIcon />}
            >{option.name}</Button>
          )
          )}

          {text_options?.map((option, index) => (
             <Button
              variant="navy icon-stacked"
              onClick={() => handleSelection(option.members)}
              key={`${option.name}-${index}`}
              icon={<FontIcon />}
            >{option.name}</Button>
          )
          )}


          {photo_options?.map((option, index) => (
             <Button
              variant="navy icon-stacked"
              onClick={() => handlePhotoSelection(option.members)}
              key={`${option.name}-${index}`}
              icon={<PhotoIcon />}
            >{option.name}</Button>
          )
          )}

          <Button
            variant="navy icon-stacked"
            onClick={() => setShowConfirm(true)}
            icon={<FontIcon />}
          >AddText</Button>

        </ButtonGroup>
      </HorizontalScroll>
    </Toolbar>
  </>
  )
}

export { OptionsToolbar }

function addTextToCanvas(canvas) {
  const background = canvas.value.getObjects().find(obj => obj.id === "background");

  if (!background) {
    alert('There was an error adding text to the canvas, if this is something you need to do to complete your project, please contact support.')
    return;
  }

  // Calculate the center of the background
  // const bgCenterX = background.left + (background.width * background.scaleX) / 2;
  // const bgCenterY = background.top + (background.height * background.scaleY) / 2;
  const bgCenterX = (background.width * background.scaleX) / 2;
  const bgCenterY = (background.height * background.scaleY) / 2;

  // The goal is to size the text largely relative to the background
  const textSizeY = (background.height) * 0.10;
  const textSizeX = (background.width) * 0.10;
  const textSize = Math.max(textSizeX, textSizeY);

  // Create the text object
  const newText = new IText('CLICK TO EDIT', {
    fill: 'red',
    fontSize: textSize,
    isDeletable: true
  });

  canvas.value.add(newText);
  canvas.value.renderAll();

  // Calculate the exact positions after the object has been rendered
  const textWidth = newText.getScaledWidth();
  const textHeight = newText.getScaledHeight();

  // Set position and adjust based on the text's dimensions
  newText.set({
    left: bgCenterX,
    top: bgCenterY
  });

  canvas.value.renderAll();

  // Adjust position after rendering
  newText.set({
    left: newText.left - textWidth / 2,
    top: newText.top - textHeight / 2
  });

  newText.setCoords();
  canvas.value.renderAll();
}

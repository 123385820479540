import Processing from "@/components/Processing/Processing";
import { Button } from '@/components/Button'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useState } from 'react';
import { generateThumbnailAndUploadToS3, serializeCanvasAndUploadToS3, uploadCanvasImagesToS3 } from "@/utils";

// signals
import { canvas } from "@/signals/canvas";

const AddToCartButton = () => {

  const [isProcessing, setIsProcessing] = useState(false);

  const handleAddToCart = async () => {
    // This function should 
    // generate a thumbnail image and upload that to S3
    // the thumbnail should be named with a uuid
    // It should also upload the fabric/canvas JSON to S3
    // the json should be named with a uuid
    // upload all images to S3 (from the users canvas)
    // the images should be named with a uuid
    // and then call window.add_to_cart
    
    setIsProcessing(true);
    
    // generate a thumbnail image and upload that to S3
    const thumbnailUrl = await generateThumbnailAndUploadToS3(canvas);
    const canvasImageUrls = await uploadCanvasImagesToS3(canvas);
    const canvasUrl = await serializeCanvasAndUploadToS3(canvas);

    // Now that all images are uploaded, you can proceed to add to cart
    // thumnailUrl, canvasUrl, canvasImageUrls
    await window.add_to_cart( thumbnailUrl, canvasUrl, canvasImageUrls );

    setIsProcessing(false);
  };

  if (isProcessing) return <Processing message="Uploading Images for your order..." />

  return (
    <Button
      icon={<ShoppingCartIcon />}
      onClick={handleAddToCart}
    >
      Add To Cart
    </Button>

  )
}

export default AddToCartButton
// signals
import { canvas } from "@/signals/canvas";
// components
import { NavyButton } from "@/components/Button";
// hooks
import { useSkuConfig } from "@/hooks";

const SaveCanvasButton = () => {
  const { config } = useSkuConfig();
  const saveCanvas = async (canvasInstance) => {
    try {
      canvasInstance.value.sizeKey = config.sizeKey;
      canvasInstance.value.enableRetinaScaling = false;
      let serializedCanvas = canvasInstance.value.toDatalessJSON(['src', 'sizeKey']);

      const blob = new Blob([JSON.stringify(serializedCanvas)], { type: "application/json" });

      const link = document.createElement('a');
      link.download = 'canvas.json';
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Failed to save canvas:', error);
    }
  };

  return (
    <NavyButton onClick={() => saveCanvas(canvas)}>
      SAVE CANVAS
    </NavyButton>
  )
}

export default SaveCanvasButton;

import React, { useEffect, useState } from 'react';
import { filters, Image } from 'fabric'; // TODO: Image will be FabricImage when beta is updated
import { Toolbar } from '@mui/material';
import { CameraIcon } from '@/components/Icons';
import { Button, OrbButton } from '@/components/Button';
import heic2any from "heic2any";
import { createThumbnail, saveImage, saveThumbnail } from '@/dexie/dbHelpers';
// signals
import { canvas } from '@/signals/canvas';
import { selection } from '@/signals/selection';
// utils
import { uploadImageToS3 } from '@/utils';
import { compressImage } from '@/utils/compressImage';
import { makeSelection } from '@/utils/select';


const ImageControlUiToolbar = ({/*onUploadComplete, setGalleryVisible, galleryVisible*/ }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const addImageToCanvas = async (url) => {

    console.log('addImageToCanvas url:', url);
    console.log('canvas.value.getActiveObject():', canvas.value.getActiveObject());

    // return


    // I'm guessing this is like this because sometimes the object is selected programmatically and 
    // sometimes it is selected by the user clicking on it
    // const activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id; // TODO: this may be handled by selection signal
    const activeObjectID = selection.value;

    // this is needed so that the selection is updated if direct selection is made
    makeSelection(canvas, activeObjectID)
    // alert('we are removing the active object id of:', activeObjectID);

    // delete the existing image if there is one
    // This is to deal with group or direct selection

    // When we upload an image there will either be a placeholder image or a user uploaded image
    // either way, we need to remove the existing image before adding a new one, when the user uploads a new image
    // we need to remove the existing image before adding a new one.
    // However, the

    // const activeObject = canvas.value.getActiveObject();
    // console.log('we will be deleting activeObject.id:', activeObject);
    // console.log(canvas.value.getObjects().find(o => o.id === selection.value))
    const clipped_image = canvas.value.getObjects().find(o => o.clipPathID === selection.value);
    console.log('clipped_image:', clipped_image.id, clipped_image.clipPathID, clipped_image);
    canvas.value.remove(clipped_image);
    // canvas.value.renderAll();

    

    // if (activeObject?.type === 'activeSelection') {
    //   for (const obj of activeObject.getObjects()) {
    //     console.log('removing obj:', obj);
    //     // Check all objects on the canvas
    //     for (const canvasObj of canvas.value.getObjects()) {
    //       // If the canvas object is an image and its clipPathID matches our activeObject's ID
    //       if (canvasObj.type === 'image' && canvasObj.clipPathID === obj.id) {
    //         canvas.value.remove(canvasObj); // Remove the old image
    //       }
    //     }
    //   }
    //   canvas.value.renderAll();
    // }

    // const image = selectedImage

    try {

      // Step 1: Load Image
      const img = await Image.fromURL(url, { crossOrigin: 'Anonymous' });
      // Assign a unique ID
      img.id = `image_${new Date().getTime()}`;

      // Step 2: Checking Active Object
      const activeGroupOrObject = canvas.value.getActiveObject();
      if (!activeGroupOrObject) return;

      // Step 3: Checking Original Path
      const originalClipPath = activeGroupOrObject.item(0);
      // trying to make sure the ClipPath does not get selected
      // and does not block the image from being selected
      originalClipPath.evented = true;

      // Step 3.5: Get the z index of the originalClipPath and log it out here
      // const zIndexOriginalClipPath = canvas.value.getObjects().indexOf(originalClipPath);
      // console.log("Z-index of originalClipPath:", zIndexOriginalClipPath);

      // Step 4: Scaling the Image
      const scaleW = originalClipPath.width / img.width;
      const scaleH = originalClipPath.height / img.height;
      const scale = Math.max(scaleW, scaleH);
      img.set({ scaleX: scale, scaleY: scale });

      // Step 4.5: 
      img.clipPathID = originalClipPath.id;
      // const scale2 = 72/300;
      // img.set({ scaleX: scale2, scaleY: scale2 });

      // Step 5: Positioning the Image
      // Calculate the position of the image relative to the canvas
      const groupTop = activeGroupOrObject.get('top');
      const groupLeft = activeGroupOrObject.get('left');

      img.set({
        top: groupTop,
        left: groupLeft
      });

      originalClipPath.absolutePositioned = true;

      // canvas.value.remove(originalClipPath)
      img.clipPath = originalClipPath;

      // trying to make sure img is not selectable
      img.selectable = false;
      img.evented = false;

      img.on('mousedown', (options) => {
        console.log('Image clicked', options);
      });

      canvas.value.add(img);

      // Get the index of obj(clipPath)
      const clipPathIndex = canvas.value.getObjects().indexOf(originalClipPath);
      // Move the image to just above the obj(clipPath)
      canvas.value.moveObjectTo(img, clipPathIndex + 1);


      canvas.value.renderAll();
      // setGalleryVisible(false);

    } catch (error) {
      console.error("Error in handleAdd:", error);
    }
  };


  const handleFileInput = async (e) => {
    e.preventDefault();  // Prevent the default file input behavior
    e.stopPropagation();  // Stop the event from propagating

    // if no file is selected, return
    if (!e.target.files.length) {
      console.log('No file selected or upload cancelled.');
      setIsLoading(false);
      return;  // Early return if no file is selected
    }

    console.log('selection: ', selection.value);

    try {
      setIsLoading(true);

      // Selected file is the file the user is trying to upload
      const selectedFile = e.target.files[0];

      // If the file is a HEIC image, convert it to JPEG
      // otherwise, just return the file
      const file = await convertHEICImagesToJPEG(selectedFile);

      // compress image
      const compressedImage = await compressImage(file);

      // Image guards
      guardAgainstNonImageFiles(compressedImage);
      guardAgainstLargeFiles(compressedImage, 10);

      // Upload the image to S3
      const url = await uploadImageToS3(compressedImage);

      console.log('url:', url);

      // Add the image to the canvas
      addImageToCanvas(url);


    } catch (error) {
      setIsLoading(false);
      // Handle the error here
      // For example, show an alert or set an error message in your state
      alert(`Unable to upload file:\n
      1. Files must be an image: jpg, png, (etc)\n
      2. File size restrictions: Image files must be less than 10MB\n
      3. You cannot upload files while in “private” browsing mode (Example: In Cognito, etc)`)
      console.log(`Failed to upload file: ${error.message}`);
    }
  };



  const handleEnglargeImage = () => {
    // This is to deal with group or direct selection
    let activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    // find the object that's clip path === activeObject)
    let img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID
    });
    img.scaleX *= 1.1;
    img.scaleY *= 1.1;
    canvas.value.renderAll();
  };


  const handleShrinkImage = () => {
    // This is to deal with group or direct selection
    let activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    let img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID;
    });
    img.scaleX *= 0.9;
    img.scaleY *= 0.9;
    canvas.value.renderAll();
  };

  const handleRotateImage = () => {
    // This is to deal with group or direct selection
    let activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    let img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID;
    });
    img.set({ originX: 'center', originY: 'center' });  // Set the origin to the center
    img.angle += 90;  // Rotate by 10 degrees
    canvas.value.renderAll();
  };

  const handleGreyscale = () => {
    let activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    let img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID;
    });

    const GrayscaleFilter = filters.Grayscale;
    const existingFilterIndex = img.filters.findIndex(filter => filter instanceof GrayscaleFilter);

    if (existingFilterIndex === -1) {
      // Filter is not applied yet, so add it
      img.filters.push(new GrayscaleFilter());
    } else {
      // Filter is already applied, so remove it
      img.filters.splice(existingFilterIndex, 1);
    }

    img.applyFilters();
    canvas.value.renderAll();
  };

  const handleSepia = () => {
    let activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    let img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID;
    });

    const SepiaFilter = filters.Sepia;
    const existingFilterIndex = img.filters.findIndex(filter => filter instanceof SepiaFilter);

    if (existingFilterIndex === -1) {
      // Filter is not applied yet, so add it
      img.filters.push(new SepiaFilter());
    } else {
      // Filter is already applied, so remove it
      img.filters.splice(existingFilterIndex, 1);
    }

    img.applyFilters();
    canvas.value.renderAll();
  };

  const handleMoveImage = (direction) => {
    // This is to deal with group or direct selection
    const activeObjectID = canvas.value.getActiveObject().clipPathID || canvas.value.getActiveObject()._objects[0].id;
    const img = canvas.value.getObjects().find(o => {
      if (o.clipPath && o.clipPath.id) return o.clipPath.id === activeObjectID;
    });

    const moveBy = 10; // Move by 10 pixels

    switch (direction) {
      case 'up':
        img.top -= moveBy;
        break;
      case 'down':
        img.top += moveBy;
        break;
      case 'left':
        img.left -= moveBy;
        break;
      case 'right':
        img.left += moveBy;
        break;
      default:
        break;
    }
    canvas.value.renderAll();
  };

  return (<>
    <Toolbar
      className='tw-gap-1 tw-flex tw-flex-row tw-justify-center tw-items-center'
    >
      <OrbButton
        variant="navy"
        onClick={() => {
          document.getElementById('image-btn').click();
          // setGalleryVisible(!galleryVisible);
        }}
        icon={<CameraIcon />}
      />
      {/* Hidden file input */}

      <input type="file"
        hidden
        id="image-btn"
        onChange={handleFileInput}
      />
      {/* onChange={handleFileInput} /> */}

      <OrbButton
        className="tw-text-white"
        variant="navy"
        onClick={handleEnglargeImage}
      >+</OrbButton>
      <OrbButton variant="navy"
        onClick={handleShrinkImage}
      >-</OrbButton>

      <Button
        variant="navy orb right"
        onClick={() => handleMoveImage('right')}
      >&#x2794;</Button>
      <Button
        variant="navy orb left"
        onClick={() => handleMoveImage('left')}
      >&#x2794;</Button>
      <Button
        variant="navy orb up"
        onClick={() => handleMoveImage('up')}
      >&#x2794;</Button>
      <Button
        variant="navy orb down"
        onClick={() => handleMoveImage('down')}
      >&#x2794;</Button>
    </Toolbar>
    <Toolbar
      className='tw-gap-2 tw-flex tw-flex-row tw-justify-center tw-items-center'
    >
      <Button
        variant="navy orb"
        onClick={() => handleRotateImage()}
      >&#8634;</Button>

      <Button
        variant="navy"
        onClick={handleGreyscale}
      >
        Grayscale
      </Button>
      {/* TODO: There was an issue with deserializing images with SEPIA filter. This needs to be fixed
          before we can enable this feature */}
      {/* <Button
        variant="navy"
        onClick={handleSepia}
      >
        Sepia
      </Button> */}
    </Toolbar>
  </>
  );
};

export default ImageControlUiToolbar;


function guardAgainstNonImageFiles(file) {
  if (!file.type.startsWith('image/')) {
    throw new Error('File is not an accepted image format. Please contact help@peachwik.com for assistance.');
  }
}

function guardAgainstLargeFiles(file, maxSizeInMB) {
  if (file.size > maxSizeInMB * 1024 * 1024) {
    throw new Error('File is too large. Please compress it or contact help@peachwik.com for assistance');
  }
}

async function convertHEICImagesToJPEG(file) {
  if (file.type !== 'image/heic') return file;
  if (file.type === 'image/heic') {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 1
      });
      return new File([convertedBlob], file.name.replace(/\..+$/, '.jpeg'), { type: 'image/jpeg' });
    } catch (error) {
      throw new Error('Error converting HEIC image. Please contact help@peachwik.com for assistance');
    }
  }
}
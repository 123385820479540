// React and Third-party Libraries
import axios from 'axios';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Canvas, loadSVGFromURL, loadSVGFromString } from 'fabric';
import * as fabric from 'fabric';
import { useFabricTextControls } from '@/hooks/useFabricTextControls'
import { useFabricControls } from '@/hooks/useFabricControls';
// Recoil State Management
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
// import canvasAtom from '@/atoms/canvas-atom';
// Signals
import { Signal } from '@preact/signals-react';
import { canvas } from '@/signals/canvas';
import { selection } from '@/signals/selection';
// Atoms
import isCanvasReadyAtom from '@/atoms/canvas-ready-atom';
import toolbarAtom from '@/atoms/toolbar-atom';
// Fabric.js Setup
import { configureFabricGlobals } from '@/utils/fabricConfig';
import { useFabricConfig } from '@/hooks/useFabricConfig';
import { Processing } from '@/components/Processing';
import { makeSelection } from '@/utils/select';
import useResizeCanvas from '@/hooks/useResizeCanvas';
import { useSkuConfig } from '@/hooks';

const FabricCanvas = forwardRef((props, ref) => {
  console.log('FabricCanvas props', props)
  // Destructuring props for easier access
  // @ts-ignore -Typescript whines about file and boxRef not being defined
  const { boxRef, fileData, fileType } = props
  console.log("fileData", fileData)

  // Local state and refs
  const [loading, setLoading] = useState(true);
  const canvasEl = useRef(null);

  // Recoil state variables
  // const [canvas, setCanvas] = useRecoilState<Canvas>(canvasAtom)

  // Custom hooks
  useFabricControls(canvas)
  useFabricTextControls(canvas)
  useFabricConfig();
  useResizeCanvas(boxRef, loading)
  const { config} = useSkuConfig();
  // Call the function to set the global configurations

  // configureFabricGlobals(setShowConfirm); // TODO: Use or delete this

  // Recoil Setters
  const setToolbar = useSetRecoilState(toolbarAtom)
  const setIsCanvasReady = useSetRecoilState(isCanvasReadyAtom);

  // If there is a canvas, dispose of it when the component unmounts
  useEffect(() => {
    return () => {
      if (canvas && typeof canvas.dispose === 'function') {
        canvas.dispose();
      }
    };
  }, [canvas]);

  // Initialize the canvas
  useEffect(() => {
    const localCanvas = new fabric.Canvas(canvasEl.current, {
      width: 500,
      height: 500,
      backgroundColor: '#eeeeee',
      preserveObjectStacking: true,
      imageSmoothingEnabled: true
    });

    canvas.value = localCanvas;
    // setCanvas(localCanvas);
    localCanvas.renderAll();
  }, []); // Dependencies based on when you need to re-initialize

    // Load canvas data from either JSON or SVG file
    useEffect(() => {
      if (!canvas.value || !fileData) return;
  
      if (fileType === 'json') {
        console.log('loading json', fileData, canvas.value)
        loadFileFromJSON( fileData, canvas, setIsCanvasReady);
      } else if (fileType==='svg') {
        loadSVG(fileData, canvas, setIsCanvasReady);
      }
    }, [canvas.value, fileData]); // Rerun when either canvas or file changes

  // Set up the event listeners, we have some complex logic here for the type of selection
  // becuase this has to do with the toolbar state
  useEffect(() => {
    if (!canvas) return;

    function manageSelection() {
      console.log('manageSelection');
      const activeObject = canvas.value.getActiveObject();

      if (activeObject.type === 'group' || activeObject.type === 'activeSelection') {
        selection.value = activeObject.getObjects()[0].id;
      } else {
          selection.value = activeObject.id;
      }
    
      // Early return if there's no active object, after setting the toolbar state
      if (!activeObject) {
        setToolbar('main');
        return;
      }

      // Simplify readability by extracting complex conditions
      const isActiveObjectAGroupWithOnePhoto = activeObject instanceof fabric.Group &&
        activeObject._objects.length === 1 &&
        activeObject._objects[0].id.includes('photo');

      const isDirectPhotoSelection = activeObject.clipPathID?.includes('photo');

      const isActiveObjectASingleText = activeObject._objects &&
        activeObject._objects.length === 1 &&
        activeObject._objects[0].type.includes('text');

      // Check if the active object is a group that only contains text
      // In the future I want to try and make a special selection for this
      // and maybe a special toolbar for it. The purpose of it will be so you
      // can edit multiple fonts at once
      const isActiveObjectAGroupThatOnlyContainsText = activeObject instanceof fabric.Group &&
        activeObject._objects.every((obj) => obj.type === 'text' || obj.type === 'i-text');

      // Apply the simplified conditions
      if (isActiveObjectAGroupWithOnePhoto || isDirectPhotoSelection) {
        setToolbar('photo');
      } else if (isActiveObjectASingleText) {
        setToolbar('text');
      } else {
        setToolbar(activeObject.type);
      }
    }


    canvas.value.on('selection:created', manageSelection);
    canvas.value.on('selection:updated', manageSelection);
    // canvas.value.on('selection:cleared', manageSelection);

    return () => {
      canvas.value.off('selection:created', manageSelection);
      canvas.value.off('selection:updated', manageSelection);
      // canvas.value.off('selection:cleared', manageSelection);
    };
  }, [canvas.value, loading]);


  return <>
    {loading && <Processing message="Loading The Artwork :)" />}
    <canvas ref={canvasEl} />
  </>

  function loadSVG(fileData: string, c: Signal, setIsCanvasReady: (isReady: boolean) => void) {
    // check if svg file has been loaded, if so remove all objects
    // TODO: This is a temporary fix, we should find a better way to handle this,
    // the reason I added this was because I was getting a bunch of dupicate objects.
    // For example, all the text objects were getting added to the canvas twice and that
    // made it so my 'selects' for those objects were not working and they appeared as a
    // color selection.
    if (c.value.getObjects().length > 0) {
      c.value.clear()
    }

    function myReviver(svgElement, fabricObject) {
      if (svgElement.tagName === 'text') {
        console.log('A text element is being processed');
        svgElement.textContent = 'This is a text element\nSo it has two lines\n\nNew';
        fabricObject.set('text', 'This is a text element\nSo it has two lines\n\nNew');
        // Modify the fabricObject based on the svgElement, if needed
      }
    }

    function tspanReviver(svgElement, fabricObject) {
      if (svgElement.tagName === 'text') {
        // Initialize an empty array to hold the tspan lines
        let tspanLines = [];

        // Initialize variables to hold style attributes
        let firstTspan = true;
        let fontSize, fontFamily, fill;

        // Loop through child nodes to find tspan elements
        for (let i = 0; i < svgElement.childNodes.length; i++) {
          let childNode = svgElement.childNodes[i];
          if (childNode.tagName === 'tspan') {
            // Get the text content of each tspan and push it to the array
            tspanLines.push(childNode.textContent);

            // Capture style attributes from the first tspan
            if (firstTspan) {
              fontSize = childNode.getAttribute('font-size');
              fontFamily = childNode.getAttribute('font-family');
              fill = childNode.getAttribute('fill');
              firstTspan = false;
            }
          }
        }

        // Join the tspan lines into a single string separated by line breaks
        if (tspanLines.length > 0) {
          fabricObject.set('text', tspanLines.join('\n'));
        }

        // Apply captured style attributes to the fabric object
        if (fontSize) fabricObject.set('fontSize', parseFloat(fontSize));
        if (fontFamily) fabricObject.set('fontFamily', fontFamily);
        if (fill) fabricObject.set('fill', fill);
        fabricObject.set('textAlign', 'center');
      }
    }


    loadSVGFromString(fileData, (objects, options) => {
      if (!objects || objects.length === 0) {
        console.error(`Error loading SVG, maybe there is no file for this in /public/svg.`);
        return;
      }

      setIsCanvasReady(true);

      // if a config.clipPath is present, we should use it to clip the canvas
      if(config.clipPath) {
        const clipPath = objects.find(o => {
          return o.id === config.clipPath}
          );
        c.value.clipPath = clipPath;
        c.value.renderAll();
      }

       // if the artwork has a 'viewbox' attribute, use it to clip the canvas
       const viewBox = objects.find(o => o.id === 'viewbox');
       if(viewBox) {
        c.value.clipPath = viewBox;
        c.value.renderAll();
      }

      console.debug('canvas is ready, loaded from svg')
      console.log(options.height)
      console.log(options.width)
      const scaleH = c.value.height / options.height
      const scaleW = c.value.width / options.width
      const scale = Math.min(scaleH, scaleW)

      if (objects.length === 0) console.error('No objects found in SVG file, this probaly means you did not put file on aws')

      // seperate layers from text layers
      const layers = objects.filter(o => o.type !== 'text')
      const textLayers = objects.filter(o => o.type === 'text')

      for (const layer of layers) {
        layer.selectable = false
        layer.hasControls = false
        layer.hasBorders = false
        // Make sure reserved works are not selectable
        const reserved = ['mask', 'guide']
        layer.evented = reserved.includes(layer.id) ? false : true
        c.value.add(layer)
      }

      for (const textLayer of textLayers) {
        const itext = new fabric.IText(textLayer.text, {
          id: textLayer.id,
          left: textLayer.left,
          top: textLayer.top,
          fontSize: textLayer.fontSize,
          fontFamily: textLayer.fontFamily,
          fill: textLayer.fill,
          selectable: true
        })
        c.value.add(itext)
      }

      c.value.setZoom(scale)
      c.value.set('height', options.height * scale)
      c.value.set('width', options.width * scale)

      c.value.renderAll()
    }, tspanReviver, {
      crossOrigin: 'anonymous'
    })

    setLoading(false)
  }

  // set c as type canvas
  async function loadFileFromJSON(fileData: string, c: Signal, setIsCanvasReady: (isReady: boolean) => void) {
    let scale = 1

    await c.value.loadFromJSON(fileData, (x) => {

      // make the layers unselectable
      if (x.type === 'group') {
        x.selectable = false
        x.hasControls = false
        x.hasBorders = false
      }

      // evented is false for reserved layers
      // this is so that they cannot be selected
      const reserved = ['mask', 'guide']
      x.evented = reserved.includes(x.id) ? false : true

       // Ensure every image has the crossOrigin attribute set properly
       if (x.type === 'image') {
        console.log('setting crossOrigin for image', x)
      }


      // // Scale the canvas to fit the background
      // if (x?.id === 'background') {
      //   scale = c.height / x.height
      //   c.setZoom(scale)
      //   c.setDimensions({
      //     width: x.width * scale,
      //     height: x.height * scale
      //   })
      // }

    });

    // implement scale here because of fabric error
    const background = c.value.getObjects().find(o => o.id === 'background')
    if (background) {
      scale = c.value.height / background.height
      c.value.setZoom(scale)
      c.value.setDimensions({
        width: background.width * scale,
        height: background.height * scale
      })
    }


    // This creates clickable targets for the clip paths/images
    // The images are not directly selectable because they may interfere
    // with the other canvas objects
    for (const layer of c.value.getObjects()) {
      if (layer.type === 'image' && layer.clipPath) {
        layer.selectable = false
        layer.evented = false
        const target = new fabric.Rect({
          lockMovementX: true,
          lockMovementY: true,
          left: layer.clipPath.left,
          top: layer.clipPath.top,
          width: layer.clipPath.width,
          height: layer.clipPath.height,
          scaleX: layer.clipPath.scaleX,
          scaleY: layer.clipPath.scaleY,
          fill: 'transparent',
          target: layer.clipPath.id
        })
        // add a click event to the target
        target.on('mousedown', (e) => {
          makeSelection(c, [target.target])
        })
        c.value.add(target)
      }
    }

    c.value.renderAll();

    console.log('canvas is ready, loaded from json', c.value)
    setIsCanvasReady(true);
    setLoading(false)
  }

})
FabricCanvas.displayName = "FabricCanvas";
export default FabricCanvas
import generateThumbnailAndUploadToS3 from './generateThumbnailAndUploadToS3';
export { generateThumbnailAndUploadToS3 };

import serializeCanvasAndUploadToS3 from './serializeCanvasAndUploadToS3';
export { serializeCanvasAndUploadToS3 };

import uploadCanvasImagesToS3 from './uploadCanvasImagesToS3';
export { uploadCanvasImagesToS3 }

import uploadImageToS3 from './uploadImageToS3';
export { uploadImageToS3 };


// for shadcn
import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

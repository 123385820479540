import Compressor from 'compressorjs';

/**
 * Compresses an image file.
 * @param {File} file - The file to be compressed.
 * @param {number} quality - The quality of the output (0.1 to 1).
 * @returns {Promise<File>} A promise that resolves with the compressed image file.
 */
export function compressImage(file, quality = 0.8) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: quality,
            convertSize: 500000, // Convert image file > 500KB to JPEG with the given quality.
            checkOrientation: true,
            strict: true,
            retainExif: false,
            success: function (compressedResult) {
                resolve(compressedResult);
            },
            error: function (err) {
                reject(err);
            },
        });
    });
}

import { forwardRef, useState } from "react";
import { AppBar, Button, Toolbar } from "@mui/material";
import { useRecoilState } from "recoil";
import React, { useRef } from 'react';
import { NavyButton } from "@/components/Button";
import shipstationProjectAtom from "@/atoms/shipstation-project-data-atom";
// utils
import { generateThumbnailAndUploadToS3, serializeCanvasAndUploadToS3, uploadCanvasImagesToS3 } from "@/utils";
import { Processing } from "@/components/Processing";
// signals
import { canvas } from "@/signals/canvas";


const ShipStationHeader = forwardRef((props, ref) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const [shipstationProjectData, setShipstationProjectData] = useRecoilState(shipstationProjectAtom);
  // Safeguarding access to payload
  const { order, orderUuid, projectUuid, isAdmin, orderNumber, type } = shipstationProjectData?.payload || {};

  const handleClose = (isAdmin) => {

    // Inform the parent window to update its location
    const message = {
      type: 'updateLocation',
      payload: {
        newLocation: isAdmin ? `/admin/orders/${orderUuid}` : `/orders/${orderUuid}` 
      }
    };
  
    window.parent.postMessage(message, import.meta.env.VITE_PARENT_IFRAME_APP_URL);  // Replace with your parent window's origin for better security
  };
  
  const saveCanvas = async () => {
    // This function should 
    // generate a thumbnail image and upload that to S3
    // the thumbnail should be named with a uuid
    // It should also upload the fabric/canvas JSON to S3
    // the json should be named with a uuid
    // upload all images to S3 (from the users canvas)
    // the images should be named with a uuid

      // If there is a selection, remove it
      const activeObject = canvas.value.getActiveObject();
      if (activeObject) {
        activeObject.set('active', false);
        canvas.value.discardActiveObject();
        canvas.value.renderAll();
      }
    
    setIsProcessing(true);
    
    // generate a thumbnail image and upload that to S3
    const thumbnailUrl = await generateThumbnailAndUploadToS3(canvas);
    console.log("thumbnailUrl:", thumbnailUrl);

    console.log("Starting handleAddToCart function...");

    const canvasImageUrls = await uploadCanvasImagesToS3(canvas);

    console.log("image urls:", canvasImageUrls);

    const canvasUrl = await serializeCanvasAndUploadToS3(canvas);
    console.log("canvasUrl:", canvasUrl);

    // Inform the parent window of the urls
    const message = {
      type: 'projectUrls',
      payload: {
        orderUuid,
        projectUuid,
        thumbnailUrl,
        canvasImageUrls,  
        canvasUrl,
      }
    };

    window.parent.postMessage(message, import.meta.env.VITE_PARENT_IFRAME_APP_URL);

    setIsProcessing(false);
  };

  // This header is used for two modes, preview and post order
  if(type !== 'shipstationPreview' && !order) return (<Processing message="Please wait. Loading your Order" /> ) 
  if(isProcessing) return (<Processing message="Please wait. Your item is being saved" />)

  return (
    <header ref={ref}>
      <AppBar position="static">
       
        {/* align content of toolbar on the right */}
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            justifyContent: 'flex-end',
          }}
        >

        {isAdmin 
        ? <NavyButton
          primary
          onClick={() => savePNG(canvas, orderNumber, projectUuid)}
          >SAVE PNG</NavyButton>
        : null }

        {isAdmin 
        ? <NavyButton
          primary
          onClick={() => saveSVG(canvas, orderNumber, projectUuid)}
          >SAVE SVG</NavyButton>
        : null }

        {type !== 'shipstationPreview' ?
        <NavyButton
          primary
          label="Close"
          onClick={()=>handleClose(isAdmin)}
          >Close</NavyButton>
          : null }

        {type !== 'shipstationPreview' ?
        <NavyButton
          primary
          label="Save"
            onClick={saveCanvas}
          >Save</NavyButton>
          : null }
          
        </Toolbar>
      </AppBar>
    </header>
  );
})

ShipStationHeader.displayName = "ShipStationHeader";
export default ShipStationHeader;

const savePNG = (canvas, orderNumber, projectUuid) => {
  // Convert canvas to PNG data URL

  // If there is a selection, remove it
  const activeObject = canvas.value.getActiveObject();
  if (activeObject) {
    activeObject.set('active', false);
    canvas.value.discardActiveObject();
    canvas.value.renderAll();
  }

  // remove the layers for production
  const layers = ['guide', 'mask', 'edge'];
  for (const object of canvas.value.getObjects()) {
    if (layers.includes(object.id)) {
      canvas.value.remove(object);
    }
  }

  const scale = canvas.value.getZoom();
  const desiredMultiplier = 300 / 72;
  const effectiveMultiplier = desiredMultiplier / scale;

  const image = canvas.value.toDataURL({
    format: 'png',
    quality: 1,
    multiplier: effectiveMultiplier  // This will use a multiplier of 8.33
  });

  // If you'd like to allow the user to download the image:
  const link = document.createElement('a');
  link.href = image;
  link.download = `${orderNumber}-${projectUuid}.png`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

};

const saveSVG = (canvas, orderNumber, projectUuid) => {
  // remove the layers for production
  const layers = ['guide', 'mask', 'edge'];
  for (const object of canvas.value.getObjects()) {
    if (layers.includes(object.id)) {
      canvas.value.remove(object);
    }
  }

  const svg = canvas.value.toSVG();
  const svgBlob = new Blob([svg], { type: "image/svg+xml;charset=utf-8" });
  const svgUrl = URL.createObjectURL(svgBlob);

  const downloadLink = document.createElement("a");
  downloadLink.href = svgUrl;
  downloadLink.download = `${orderNumber}-${projectUuid}.svg`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

import { canvas } from '@/signals/canvas';

const ZoomToViewboxButton = () => {

  function zoomToObject(canvas, objectName) {
    console.log('Zooming to object:', objectName);
    const obj = canvas.value.getObjects().find(obj => obj.name === objectName);

    console.log('Canvas Objects:', canvas.value.getObjects())

    console.log('Object:', obj)
    
    if (!obj) {
      console.log('Object not found');
      return;
    }
    
    // Assuming the initial zoom level is considered in the object's scale,
    // calculate the desired scale based on the canvas size and the object size
    const scaleX = canvas.value.width / obj.getScaledWidth();
    const scaleY = canvas.value.height / obj.getScaledHeight();
    const scaleToFit = Math.min(scaleX, scaleY) * 0.8; // Adjust for padding and to avoid overscaling
    
    // Consider current zoom to adjust
    const currentZoom = canvas.value.getZoom();
    const adjustedScale = scaleToFit / currentZoom * currentZoom;
    console.log('Adjusted scale:', adjustedScale);
    
    // Calculate the position to center the object, considering the current zoom
    const center = obj.getCenterPoint();
    const zoomPoint = new fabric.Point(center.x, center.y);
    canvas.value.zoomToPoint(zoomPoint, adjustedScale);
    
    const deltaX = (canvas.value.width / 2 - zoomPoint.x * adjustedScale) / currentZoom;
    const deltaY = (canvas.value.height / 2 - zoomPoint.y * adjustedScale) / currentZoom;
    canvas.value.relativePan(new fabric.Point(deltaX, deltaY));

    canvas.value.requestRenderAll();
  }

  return (
    <button type="button" onClick={() => zoomToObject(canvas, 'viewbox')}>
      Zoom to Viewbox
    </button>
  );
};

export default ZoomToViewboxButton;

// This function should generate a thumbnail from the canvas and upload it to S3
// The function will need to call a netlify function to generate a presigned url, which will be 
// // `${import.meta.env.VITE_NETLIFY_BASE_URL}/generate-presigned-url-thumbnails`
// The thumbnail should be a jpeg with a quality of 50%
// the thumbnail should be named a uuid
// The function should return the url of the thumbnail which will be 
// aws s3 bucket: peachwik-app/order-thumbnails/

import { v4 as uuidv4 } from 'uuid';

// This takes the fabric canvas object directly, not the signal
const generateThumbnailAndUploadToS3 = async (canvas) => {
  console.log("Generating thumbnail...");

  // Generate thumbnail from canvas
  const thumbnailDataUrl = canvas.value.toDataURL('image/jpeg', 0.5);

  // Convert DataURL to Blob
  const blob = await (await fetch(thumbnailDataUrl)).blob();

  // Generate a unique filename using UUID
  const uuid = uuidv4(); // You need to implement or import a UUID generator
  const filename = `${uuid}.jpeg`;

  console.log("Generated thumbnail filename:", filename);

  // Get a pre-signed URL from the server
  const response = await fetch(`${import.meta.env.VITE_NETLIFY_BASE_URL}/generate-presigned-url-thumbnails`, {
    method: 'POST',
    body: JSON.stringify({
      filename: filename,
      filetype: 'image/jpeg',
    }),
  });

  const { url } = await response.json();

  // Use the pre-signed URL to upload the thumbnail directly to S3
  const uploadResult = await fetch(url, {
    method: 'PUT',
    body: blob,
    headers: {
      'Content-Type': 'image/jpeg',
    },
  });

  if (uploadResult.ok) {
    console.log("Thumbnail uploaded successfully.");
    const s3Url = url.split('?')[0]; // Store the clean URL without signed params
    return s3Url;
  } else {
    const text = await uploadResult.text();
    console.log('Thumbnail upload failed. Error:', text);
    return null; // Or handle the error as you see fit
  }
};

export default generateThumbnailAndUploadToS3;
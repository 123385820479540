import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import OptionItem from "./OptionItem";
import { useEffect } from 'react';



const OptionsList = ({ handleMemberChange, handleNameChange,handleDeleteMember, handleDeleteOption, 
  colorOptions, textOptions, photoOptions,
  setColorOptions, setTextOptions, setPhotoOptions,
  onEditOption, onRemoveOption }) => {

    const reorderOptions = (optionType, startIndex, endIndex) => {
      console.log(`Reordering ${optionType}: start ${startIndex}, end ${endIndex}`);
    
      const optionsMap = {
        color: colorOptions,
        text: textOptions,
        photo: photoOptions,
      };
      const setOptionsMap = {
        color: setColorOptions,
        text: setTextOptions,
        photo: setPhotoOptions,
      };
    
      if (!optionsMap[optionType] || !setOptionsMap[optionType]) {
        console.error('Invalid optionType or optionType not found:', optionType);
        return;
      }
    
      const result = Array.from(optionsMap[optionType]);
      console.log('Current order:', result.map(item => item.name || item));
    
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      
      console.log('New order:', result.map(item => item.name || item));
    
      // added this same propblem
      setOptionsMap[optionType]([...result]);
    };
    
    const onDragEnd = (result) => {
      console.log('Drag ended:', result);
    
      const { source, destination } = result;
    
      if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
        console.log('Dropped outside or in the original position');
        return;
      }
    
      const optionTypeMap = {
        'droppable-colors': 'color',
        'droppable-texts': 'text',
        'droppable-photos': 'photo',
      };
    
      const optionType = optionTypeMap[source.droppableId];
    
      if (!optionType) {
        console.error('Invalid optionType or optionType not found:', source.droppableId);
        return;
      }
          
      reorderOptions(optionType, source.index, destination.index);
    };


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="tw-w-full">
        <Droppable droppableId="droppable-colors">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {colorOptions.map((option, index) => (
                <OptionItem
                  key={`cl-${option.name}`}
                  setColorOptions={setColorOptions}
                  option={option}
                  optionType="color"
                  index={index}
                  handleDeleteOption={() => handleDeleteOption('color', index)}
                  handleNameChange={handleNameChange}
                  handleMemberChange={handleMemberChange}
                  handleDeleteMember={handleDeleteMember}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Droppable droppableId="droppable-texts">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {textOptions.map((option, index) => {
                return (
                <OptionItem
                key={`tl-${option.name}`}
                  option={option}
                  optionType="text"
                  index={index}
                  handleDeleteOption={() => handleDeleteOption('text', index)}
                  handleNameChange={handleNameChange}
                  handleMemberChange={handleMemberChange}
                  handleDeleteMember={handleDeleteMember}
                />
              )})}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Droppable droppableId="droppable-photos">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {photoOptions.map((option, index) => (
                <OptionItem
                  key={`pl-${option.name}`}
                  option={option}
                  optionType="photo"
                  index={index}
                  handleDeleteOption={() => handleDeleteOption('photo', index)}
                  handleNameChange={handleNameChange}
                  handleMemberChange={handleMemberChange}
                  handleDeleteMember={handleDeleteMember}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

      </div>
    </DragDropContext>
  );
};

export default OptionsList;

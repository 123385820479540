// ContextProviders.jsx
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@mui/material/styles';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import theme from '@/theme/theme';

import { Toaster } from "@/components/ui/sonner"


const queryClient = new QueryClient();

function ContextProviders({ children }) {
  console.log('ContextProviders.jsx')
  return (
    <QueryClientProvider client={queryClient}>
        <ScopedCssBaseline>
          <ThemeProvider theme={theme}>
            <Toaster 
              richColors expand={true} 
              toastOptions={{
                style: {
                  background: 'var(--peachwik-navy-dark)',
                },
                className: 'class',
              }}
              />
            {children}
          </ThemeProvider>
        </ScopedCssBaseline>
    </QueryClientProvider>
  );
}

export default ContextProviders;

import { signal } from "@preact/signals-react";

// sku will be stored in window.sku when the app is laoded in Shopify

//  Examples of project types
// export const sku = signal(window.sku || "wsm1003-sm-matte");
// export const sku = signal(window.sku || "wsm1003-lg-canvas");
// export const sku = signal(window.sku || "gba1001-50-canvas");
// export const sku = signal(window.sku || "gba1050-50-canvas"); // huge file, complex svg
// export const sku = signal(window.sku || "rat1001-8x10");
// export const sku = signal(window.sku || "fpp1002");
// export const sku = signal(window.sku || "wsm1004-lg-canvas");
// export const sku = signal(window.sku || "orntest2");
// export const sku = signal(window.sku || "onc0045");

// DEBUG
// export const sku = signal(window.sku || "fpp1012"); // TODO fix this sku

// Current
export const sku = signal(window.sku || "fpp1029");
// WIP: need to tweak this to get crop function working correctly.

// signals
import { canvas } from "@/signals/canvas";
// components
import { NavyButton } from "@/components/Button";
// hooks
import { useSkuConfig } from "@/hooks";

const CropCanvasButton = () => {
  const { config } = useSkuConfig();
  const artboard = config.artboard;
  const crop = async(canvasInstance) => {
    // remove clip path
    canvasInstance.value.clipPath = null;
    const cropBox = canvasInstance.value.getObjects().find((obj) => obj.id === artboard);
    console.log('cropBox', JSON.stringify(cropBox, null, 2));

    // cropBox.set('fill', "red");
    const zoom = canvasInstance.value.getZoom();
    const height = canvasInstance.value.height;
    const width = canvasInstance.value.width;
    console.log('height', height, 'width', width);
    console.log('zoom', zoom);

    const offsetTop = cropBox.top;
    const offsetLeft = cropBox.left;
    const cropBoxWidth = cropBox.width * zoom;
    const cropBoxHeight = cropBox.height * zoom;

    console.log('cropBoxHeight', cropBoxHeight, 'cropBoxWidth', cropBoxWidth);
    console.log('cropBoxHeigh zoom', cropBoxHeight * zoom, 'cropBoxWidth zoom', cropBoxWidth * zoom);

    // move all objects by the offset
    for (const obj of canvasInstance.value.getObjects()) {
      console.log('obj', obj.id, obj.left, obj.top)
      obj.left -= offsetLeft;
      obj.top -= offsetTop;
      console.log('obj', obj.id, obj.left, obj.top)
    }

    // set canvas size to crop box size
    canvasInstance.value.setWidth(cropBoxWidth);
    canvasInstance.value.setHeight(cropBoxHeight);
    canvasInstance.value.renderAll();

    window.dispatchEvent(new Event('resize'));
    
  }

  return (
    <NavyButton
      onClick={() => crop(canvas)}
    >
      CROP CANVAS
    </NavyButton>
  )
}

export default CropCanvasButton;
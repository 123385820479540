import React, { useState } from 'react';
import { renameSvgId } from '../../utils'; // Adjust the import path as necessary

const layerColors = {
    color: 'tw-bg-blue-200',
    text: 'tw-bg-green-200',
    photo: 'tw-bg-red-200',
};

const LayerObject = ({ layer, svgFileName, svgContent, updateSvgContentAndParseIds, addOption, saveSvgToFile }) => {
    const [layerName, setLayerName] = useState(layer.name);

    const handleNameChange = (event) => {
        setLayerName(event.target.value);
    };

    const handleRename = () => {
        // Perform client-side SVG ID renaming
        const updatedSvgContent = renameSvgId(svgContent, layer.name, layerName);
        updateSvgContentAndParseIds(updatedSvgContent); // Update state with new SVG content
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleRename();
            saveSvgToFile();
        }
    };

    return (
        <div className={"tw-flex tw-flex-row tw-border tw-border-black tw-border-1 tw-border-b-0 last:tw-border-1"}>
            <div className={"tw-flex tw-flex-0 tw-w-8 tw-h-8 tw-align-middle tw-justify-center tw-bg-peachwik-mint-light"}>=</div>
            <input
              className={`tw-flex tw-flex-1 tw-p-2 tw-h-8 ${layerColors[layer.type] || ''}`}
              type="text"
              value={layerName}
              onChange={handleNameChange}
              onKeyPress={handleKeyPress}
            />
            <button className={"tw-flex tw-grow-0 tw-w-8 tw-h-8 tw-align-middle tw-justify-center tw-bg-peachwik-mint-light"}onClick={addOption}>+</button>
        </div>
    );
};

export default LayerObject;

import { useQuery } from 'react-query';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { useSkuConfig } from '@/hooks/useSkuConfig';
import { shipstationProjectDataAtom } from '@/atoms';
// Signals
import { sku } from '@/signals/sku';

const fetchFabricJSON = async (url) => {
  try {
    const response = await axios.get(url);
    if (response.headers['content-type'].includes('application/json')) {
      console.log('Fabric JSON:', response.data);
      return response.data;
    } 
    throw new Error('Expected JSON response, got HTML.');
  } catch (error) {
    console.error('Error fetching fabric JSON:', error);
    throw error;  // Rethrow to handle it in the calling function
  }
};
  
const useSerializedFabricCanvasJSON = (shouldILoadJSON = true) => {
  if (!shouldILoadJSON) {
    return { data: null, error: 'JSON loading is disabled.', isLoading: false };
  }

  console.log('Loading Fabric JSON:', shouldILoadJSON);
  const { config } = useSkuConfig();
  const file = config.file;
  const shipstationProjectData = useRecoilValue(shipstationProjectDataAtom);

  console.log('Shipstation project data:', shipstationProjectData);

  const baseCDNUrl = `${import.meta.env.VITE_CLOUDFRONT_CDN_URL}/json/${file}`; // TODO - this should be on the cloudfront cdn
  // const baseCDNUrl = `${import.meta.env.VITE_S3_BUCKET}/json/${file}`; // TODO - this should be on the cloudfront cdn
  const url = shipstationProjectData?.payload.project.canvasUrl || baseCDNUrl;

  const queryKey = ['fabricJSON', sku.value]; // Ensures the query is unique per SKU
  const { data, isLoading, error } = useQuery(
    queryKey,
    () => fetchFabricJSON(url),
    {
      enabled: !!sku.value && shouldILoadJSON, // Only run the query if sku is not null/undefined and shouldILoadJSON is true
      refetchOnWindowFocus: false
      // This takes the place of the early return and keeps the hook calls in a consistent order
      // Additional options like staleTime, cacheTime can be configured here
    }
  );

  return { data, isLoading, error };
};

export default useSerializedFabricCanvasJSON;

import React, { useState, useEffect } from 'react';
import ImageControlUiToolbar from '@/pages/Maker/components/ToolbarPhoto/ImageControlUiToolbar';

const PhotoToolbar = () => {
  return (
    <div>
      <ImageControlUiToolbar />
    </div>
  );
};


export default PhotoToolbar;

import styles from './SwatchOptions.module.css';
import React, { useState, useEffect } from 'react';
import Panel from '../Panel';

const SwatchOptions = ({ updateSwatchOptions, initialSwatchOptions }) => {
  const [selectedOptions, setSelectedOptions] = useState({
    colors: false,
    main: false,
    frame: false,
    floral: false,
    fpp: false,
  });

  // Initialize state based on loaded configuration
  useEffect(() => {
    if (initialSwatchOptions) {
      const newSelectedOptions = { ...selectedOptions };
      initialSwatchOptions.forEach(option => {
        newSelectedOptions[option.name] = true;
      });
      setSelectedOptions(newSelectedOptions);
    }
  }, [initialSwatchOptions]);

  // Handle checkbox changes
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedOptions(prev => ({ ...prev, [name]: checked }));
  };

  // Update swatch options based on the selected options
  useEffect(() => {
    const swatchOptions = Object.entries(selectedOptions)
      .filter(([key, value]) => value) // Filter out unchecked options
      .map(([key]) => ({
        name: key,
        component: key === 'colors' ? 'ColorStack' : 'SwatchesStack', // Adjust component as needed
        swatches: `${key}Swatches`,
      }));

    updateSwatchOptions(swatchOptions);
  }, [selectedOptions, updateSwatchOptions]);

  return (
    <Panel title="Swatch Options">
    <div className="tw-bg-white tw-p-4 tw-rounded tw-shadow-md">
      <label className={styles.label}>COLORS
        <input 
          className={styles.checkbox} 
          name="colors" 
          type="checkbox" 
          checked={selectedOptions.colors} // Ensure this matches the state
          onChange={handleCheckboxChange}
        />
      </label>
      <label className={styles.label}>MAIN
        <input 
          className={styles.checkbox} 
          name="main" 
          type="checkbox" 
          checked={selectedOptions.main} 
          onChange={handleCheckboxChange} // Added onChange handler
        />
      </label>
      <label className={styles.label}>FRAMES
        <input 
          className={styles.checkbox} 
          name="frame" 
          type="checkbox" 
          checked={selectedOptions.frame} 
          onChange={handleCheckboxChange} // Added onChange handler
        />
      </label>
      <label className={styles.label}>FLORAL
        <input 
          className={styles.checkbox} 
          name="floral" 
          type="checkbox" 
          checked={selectedOptions.floral} 
          onChange={handleCheckboxChange} // Added onChange handler
        />
      </label>
      <label className={styles.label}>FPP
        <input 
          className={styles.checkbox} 
          name="fpp"
          type="checkbox" 
          checked={selectedOptions.fpp} 
          onChange={handleCheckboxChange} // Added onChange handler
        />
      </label>
    </div>
    </Panel>
  );
};

export default SwatchOptions;

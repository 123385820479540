// This function should serliaze a fabric canvas and upload it to S3
// The function will need to call a netlify function to generate a presigned url, which will be
// `${import.meta.env.VITE_NETLIFY_BASE_URL}/generate-presigned-url-order-canvas-json`
// The function should return the url of the canvas which will be
// aws s3 bucket: peachwik-app/order-canvas-json/

import { v4 as uuidv4 } from 'uuid';

// This takes the fabric canvas object directly, not the signal
const serializeCanvasAndUploadToS3 = async (canvas) => {
  console.log("Serializing canvas.value.value..");

  // Serialize the canvas
  const serializedCanvas = JSON.stringify(canvas.value.toJSON());

  // Convert the serialized canvas to a Blob
  const blob = new Blob([serializedCanvas], { type: 'application/json' });

  // Generate a unique filename using UUID
  const filename = `${uuidv4()}.json`;

  console.log("Serialized canvas filename:", filename);

  // Get a pre-signed URL from the server
  const response = await fetch(`${import.meta.env.VITE_NETLIFY_BASE_URL}/generate-presigned-url-order-canvas-json`, {
    method: 'POST',
    body: JSON.stringify({
      filename: filename,
      filetype: 'application/json',
    }),
  });

  const { url } = await response.json();

  // Use the pre-signed URL to upload the serialized canvas directly to S3
  const uploadResult = await fetch(url, {
    method: 'PUT',
    body: blob,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (uploadResult.ok) {
    console.log("Serialized canvas uploaded successfully.");
    const s3Url = url.split('?')[0]; // Store the clean URL without signed params
    return s3Url;
  } else {
    const text = await uploadResult.text();
    console.log('Serialized canvas upload failed. Error:', text);
    return null; // Or handle the error as you see fit
  }
};

export default serializeCanvasAndUploadToS3;
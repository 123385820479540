// swatchData.js

export const mainSwatches = [
  // {name: 'grid', src: 'sw-grid.png', fullSizeDimension: 6000, webSizeDimension: 1200}, // This swatch is for testing only
  { name: 'marble', src: 'sw-marble.jpg', fullSizeDimension: 3600 },
  { name: 'burlap', src: 'sw-burlap.jpg', fullSizeDimension: 800 },
  { name: 'coffeewood', src: 'sw-coffeewood.jpg', fullSizeDimension: 3000 },
  { name: 'plaid', src: 'sw-plaid.jpg', fullSizeDimension: 400 },
  { name: 'chalkboard', src: 'sw-chalkboard.jpg', fullSizeDimension: 1700 },
  { name: 'shabby', src: 'sw-shabby.jpg', fullSizeDimension: 2048 },
  { name: 'richwood', src: 'sw-richwood.jpg', fullSizeDimension: 5080 },
  { name: 'ivorywood', src: 'sw-ivorywood.jpg', fullSizeDimension: 2048 },
  { name: 'vanilla', src: 'sw-vanilla.jpg', fullSizeDimension: 2048 },
  { name: 'darkwood', src: 'sw-darkwood.jpg', fullSizeDimension: 5080 },
  { name: 'lightwood', src: 'sw-lightwood.jpg', fullSizeDimension: 2048 },
  { name: 'plank', src: 'sw-plank.jpg', fullSizeDimension: 2048 },
  { name: 'silver', src: 'sw-silver.jpg', fullSizeDimension: 3600 },
  { name: 'golden', src: 'sw-golden.jpg', fullSizeDimension: 3600 }
];

export const floralSwatches = [
  { name: 'f1', src: 'sw-f1.jpg', fullSizeDimension: 3600, webSizeDimension: 1200 },
  { name: 'f2', src: 'sw-f2.jpg', fullSizeDimension: 3600 },
  { name: 'f3', src: 'sw-f3.jpg', fullSizeDimension: 3600 },
  { name: 'f4', src: 'sw-f4.jpg', fullSizeDimension: 3600 },
  { name: 'f5', src: 'sw-f5.jpg', fullSizeDimension: 3600 }
];

export const frameSwatches = [
  { name: 'bg2', src: 'sw-frame-bg2-300.jpg', fullSizeDimension: 3075 },
  { name: 'bg3', src: 'sw-frame-bg3-300.jpg', fullSizeDimension: 3075 },
  { name: 'bg4', src: 'sw-frame-bg4-300.jpg', fullSizeDimension: 3075 },
  { name: 'bg5', src: 'sw-frame-bg5-300.jpg', fullSizeDimension: 3075 },
  { name: 'bg6', src: 'sw-frame-bg6-300.jpg', fullSizeDimension: 3075 }
];

export const fppSwatches = [
  { name: 'fpp1', src: 'sw-fpp-bkg1.png', fullSizeDimension: 3900 },
  { name: 'fpp2', src: 'sw-fpp-bkg2.jpg', fullSizeDimension: 3900 },
  { name: 'fpp3', src: 'sw-fpp-bkg3.png', fullSizeDimension: 3900 },
  { name: 'fpp4', src: 'sw-fpp-bkg4.png', fullSizeDimension: 3900 },
  { name: 'fpp5', src: 'sw-fpp-bkg5.png', fullSizeDimension: 3900 },
  { name: 'fpp6', src: 'sw-fpp-bkg6.png', fullSizeDimension: 3900 },
  { name: 'fpp7', src: 'sw-fpp-bkg7.jpg', fullSizeDimension: 3900 },
  { name: 'fpp8', src: 'sw-fpp-bkg8.jpg', fullSizeDimension: 3900 },
  { name: 'fpp9', src: 'sw-fpp-bkg9.jpg', fullSizeDimension: 3900 },
  { name: 'fpp10', src: 'sw-fpp-bkg10.jpg', fullSizeDimension: 3900 },
  { name: 'fpp11', src: 'sw-fpp-bkg11.jpg', fullSizeDimension: 3900 },
  { name: 'fpp12', src: 'sw-fpp-bkg12.jpg', fullSizeDimension: 3900 },
  { name: 'fpp13', src: 'sw-fpp-bkg13.jpg', fullSizeDimension: 3900 },
  { name: 'fpp14', src: 'sw-fpp-bkg14.jpg', fullSizeDimension: 3900 },
  { name: 'fpp15', src: 'sw-fpp-bkg15.jpg', fullSizeDimension: 3900 },
  { name: 'fpp16', src: 'sw-fpp-bkg16.jpg', fullSizeDimension: 3900 },
  { name: 'fpp17', src: 'sw-fpp-bkg17.jpg', fullSizeDimension: 3900 },
  { name: 'fpp18', src: 'sw-fpp-bkg18.jpg', fullSizeDimension: 3900 },
  { name: 'fpp19', src: 'sw-fpp-bkg19.jpg', fullSizeDimension: 3900 },
  { name: 'fpp20', src: 'sw-fpp-bkg20.jpg', fullSizeDimension: 3900 },
  { name: 'fpp21', src: 'sw-fpp-bkg21.jpg', fullSizeDimension: 3900 },
  { name: 'fpp22', src: 'sw-fpp-bkg22.jpg', fullSizeDimension: 3900 },
  { name: 'fpp23', src: 'sw-fpp-bkg23.jpg', fullSizeDimension: 3900 },
  { name: 'fpp24', src: 'sw-fpp-bkg24.jpg', fullSizeDimension: 3900 },
  { name: 'fpp25', src: 'sw-fpp-bkg25.jpg', fullSizeDimension: 3900 },
  { name: 'fpp26', src: 'sw-fpp-bkg26.jpg', fullSizeDimension: 3900 },
  { name: 'fpp27', src: 'sw-fpp-bkg27.jpg', fullSizeDimension: 3900 },
  { name: 'fpp28', src: 'sw-fpp-bkg28.jpg', fullSizeDimension: 3900 },
];


